import request from '../../request'

export function fetchList(data) {
    return request({
        url: '/office-service/dine/book/user/list',
        method: 'post',
        data,
    })
}

export function add(data) {
    return request({
        url: '/office-service/dine/book/user/book',
        data,
        method: "post",
    })
}

export function addOverTimeMeal(data) {
    return request({
        url: '/office-service/dine/book/user/book/overtime',
        data,
        method: "post",
    })
}

export function fetchUserList(data) {
    return request({
        url: '/office-service/dine/book/user/owner/list',
        data,
        method: "post",
    })
}