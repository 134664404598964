<template>
  <div>
    <Pane> </Pane>

    <div class="container">
      <a-button
        class="button"
        type="primary"
        :loading="addLoading"
        @click="order"
        :disabled="outOfTime || hasBeen"
      >
        <span v-if="outOfTime">不在预约时间范围</span>
        <span v-else-if="hasBeen">您已预约明日工作餐</span>
        <span v-else>预约明日工作餐</span>
      </a-button>
      <span style="color: #999; padding-left: 8px"
        >预约时间：{{ startTime }} - {{ endTime }}</span
      >
      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="预约时间" data-index="date"> </a-table-column>
      </a-table>
    </div>
  </div>
</template>

<script>
import { add, fetchUserList } from "@/api/oa/meal";
import { fetchDetail } from "@/api/setting/data-dict";

import moment from "moment";
export default {
  data() {
    return {
      startTime: null,
      endTime: null,

      lastList: [], // 最新的10条预约记录

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,

      addLoading: false,
    };
  },

  computed: {
    outOfTime() {
      const now = new Date();
      const hours = now.getHours();
      if (this.startTime && this.endTime) {
        return (
          hours < parseInt(this.startTime.substring(0, 2)) ||
          hours > parseInt(this.endTime.substring(0, 2))
        );
      } else {
        return false;
      }
    },
    hasBeen() {
      return (
        this.lastList.filter(
          (item) => item.date === moment().add(1, "days").format("YYYY-MM-DD")
        ).length > 0
      );
    },
  },

  mounted() {
    fetchDetail(["worktime_book_range"]).then((res) => {
      if (Array.isArray(res) && res.length === 2) {
        const min = res.find((item) => item.value === "min") || {};
        const max = res.find((item) => item.value === "max") || {};

        if (min && max) {
          this.startTime = min.name;
          this.endTime = max.name;
        }
      }
    });

    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize } = this;
      this.loading = true;
      fetchUserList({
        pageNum: current,
        pageSize: pageSize,
        type: "lunch",
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize ? res.totalSize : 0;

            if (current === 1) {
              this.lastList = Object.freeze(res.list);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    order() {
      this.addLoading = true;
      add({
        date: moment().add(1, "days").format("YYYY-MM-DD"),
      })
        .then(() => {
          this.current = 1;
          this.getList();
        })
        .finally(() => {
          this.addLoading = false;
        });
    },
  },
};
</script>


<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 8px;
}
.button {
  margin-bottom: 8px;
}
</style>
